<template>
  <div class="tips">
    <PlatformImage image="home" title="Plataforma para gerenciamento de corridas" />
    <Logos />
    <Banner />
  </div>
</template>

<script>
import PlatformImage from 'components/platformImage'
import Logos from './Logos'
import Banner from './Banner'

export default {
  name: 'Tips',
  components: {
    PlatformImage,
    Logos,
    Banner
  }
}
</script>

<style lang="scss" scoped>
.tips {
  max-width: 470px;
  padding: 30px 0;
  position: relative;
  z-index: 1;
}
</style>
