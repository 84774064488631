<template>
  <div class="logos">
    <ul class="logos__list">
      <li>
        <img
          srcset="
            ./img/logos/ambev@3x.png 3x,
            ./img/logos/ambev@2x.png 2x,
            ./img/logos/ambev.png 1x
          "
          src="./img/logos/ambev.png"
          alt="Logo Ambev"
        >
      </li>
      <li>
        <img
          srcset="
            ./img/logos/microsoft@3x.png 3x,
            ./img/logos/microsoft@2x.png 2x,
            ./img/logos/microsoft.png 1x
          "
          src="./img/logos/microsoft.png"
          alt="Logo Microsoft"
        >
      </li>
      <li>
        <img
          srcset="
            ./img/logos/redeglobo@3x.png 3x,
            ./img/logos/redeglobo@2x.png 2x,
            ./img/logos/redeglobo.png 1x
          "
          src="./img/logos/redeglobo.png"
          alt="Logo Rede Globo"
        >
      </li>
      <li>
        <img
          srcset="
            ./img/logos/bradesco@3x.png 3x,
            ./img/logos/bradesco@2x.png 2x,
            ./img/logos/bradesco.png 1x
          "
          src="./img/logos/bradesco.png"
          alt="Logo Bradesco"
        >
      </li>
      <li>
        <img
          srcset="
            ./img/logos/kroton@3x.png 3x,
            ./img/logos/kroton@2x.png 2x,
            ./img/logos/kroton.png 1x
          "
          src="./img/logos/kroton.png"
          alt="Logo Kroton"
        >
      </li>
    </ul>

    <p class="logos__text">
      <strong>e mais de 40,000 empresas</strong> já escolheram a 99Empresas.
    </p>
  </div>
</template>

<script>
export default {
  name: 'Logos'
}
</script>

<style lang="scss" scoped>
.logos__list {
  align-items: center;
  display: inline-flex;
  list-style-type: none;
  justify-content: center;
  padding: 0;
  width: 100%;

  li {
    align-items: center;
    display: flex;
    justify-content: center;

    & + li {
      margin-left: 25px;
    }

    img {
      max-width: 100%;
    }
  }
}

.logos__text {
  color: $gray-7;
  font-family: $title-font;
  text-align: center;
  margin-top: 10px;
}
</style>
