<template>
  <v-form
    v-model="valid"
    ref="form"
    lazy-validation
    @submit.prevent="handleSubmit"
  >
    <label for="cnpj">CNPJ</label>
    <v-text-field
      v-model="form.cnpj"
      placeholder="••.•••.•••/••••-••"
      id="cnpj"
      autocomplete="off"
      v-mask="'##.###.###/####-##'"
      outlined
      required
      v-validate="{ required: true, cnpj: true }"
      :data-vv-name="'CNPJ'"
      :error-messages="errors.collect('CNPJ')"
    />

    <v-container class="pa-0">
      <v-layout>
        <v-flex xs6 sm8>
          <div class="pr-2">
            <label for="cep">CEP</label>
            <v-text-field
              v-model="form.cep"
              placeholder="•••••-•••"
              :loading="isAddressLoading"
              :disabled="isAddressLoading"
              id="cep"
              autocomplete="off"
              v-mask="'#####-###'"
              outlined
              required
              v-validate="{ required: true, cep: true }"
              :data-vv-name="'CEP'"
              :error-messages="errors.collect('CEP')"
              @input="handleGetCEP"
            />
          </div>
        </v-flex>

        <v-flex xs6 sm4>
          <div class="cep__info pl-2">
            Não sabe seu cep?<br>
            <a href="//www.buscacep.correios.com.br/sistemas/buscacep/" title="Clique aqui" target="_blank">
              Clique aqui
            </a>
          </div>
        </v-flex>
      </v-layout>
    </v-container>

    <label for="state">Estado, UF</label>
    <v-select
      v-model="form.state"
      placeholder="O estado cede da empresa"
      :loading="isAddressLoading"
      :disabled="isAddressLoading"
      :items="statesList"
      item-text="text"
      item-value="value"
      id="state"
      outlined
      required
      v-validate="{ required: true }"
      :data-vv-name="'Estado'"
      :error-messages="errors.collect('Estado')"
    />

    <v-container class="py-0">
      <v-layout row wrap>
        <v-flex xs12 sm6>
          <div :class="{ 'pr-2': !isMobile }">
            <label for="city">Cidade</label>
            <v-text-field
              v-model="form.city"
              placeholder="A cidade da empresa"
              :loading="isAddressLoading"
              :disabled="isAddressLoading"
              id="city"
              autocomplete="off"
              outlined
              required
              v-validate="{ required: true }"
              :data-vv-name="'cidade'"
              :error-messages="errors.collect('cidade')"
            />
          </div>
        </v-flex>

        <v-flex xs12 sm6>
          <div :class="{ 'pl-2': !isMobile }">
            <label for="district">Bairro</label>
            <v-text-field
              v-model="form.district"
              placeholder="O bairro da empresa"
              :loading="isAddressLoading"
              :disabled="isAddressLoading"
              id="district"
              autocomplete="off"
              outlined
              required
              v-validate="{ required: true }"
              :data-vv-name="'bairro'"
              :error-messages="errors.collect('bairro')"
            />
          </div>
        </v-flex>
      </v-layout>
    </v-container>

    <label for="address">Endereço completo igual ao cartão CNPJ</label>
    <v-text-field
      v-model="form.address"
      placeholder="Preencha apenas com a rua, avenida, travessa..."
      :loading="isAddressLoading"
      :disabled="isAddressLoading"
      id="address"
      autocomplete="off"
      outlined
      required
      v-validate="{ required: true }"
      :data-vv-name="'endereço'"
      :error-messages="errors.collect('endereço')"
    />

    <v-container class="py-0">
      <v-layout row wrap>
        <v-flex xs12 sm6>
          <div :class="{ 'pr-2': !isMobile }">
            <label for="addressNumber">Número</label>
            <v-text-field
              v-model="form.addressNumber"
              placeholder="Do prédio, casa..."
              id="addressNumber"
              autocomplete="off"
              outlined
              required
              v-validate="{ required: true }"
              :data-vv-name="'número'"
              :error-messages="errors.collect('número')"
            />
          </div>
        </v-flex>

        <v-flex xs12 sm6>
          <div :class="{ 'pl-2': !isMobile }">
            <label for="complement">Complemento (opcional)</label>
            <v-text-field
              v-model="form.complement"
              placeholder="Conjunto, ap..."
              id="complement"
              autocomplete="off"
              outlined
            />
          </div>
        </v-flex>
      </v-layout>
    </v-container>

    <label for="companySize">Número de funcionários na empresa</label>
    <v-select
      v-model="form.companySize"
      placeholder="Quantos colaboradores ela tem?"
      :items="companySizeOptions"
      item-text="text"
      item-value="value"
      id="companySize"
      outlined
      required
      v-validate="{ required: true }"
      :data-vv-name="'tamanho da empresa'"
      :error-messages="errors.collect('tamanho da empresa')"
    />

    <label for="mobilitySpending">Gasto mensal com mobilidade em R$</label>
    <v-select
      v-model="form.mobilitySpending"
      placeholder="Estimativa média mensal"
      :items="mobilitySpendingOptions"
      item-text="text"
      item-value="value"
      id="mobilitySpending"
      outlined
      required
      v-validate="{ required: true }"
      :data-vv-name="'gasto com mobilidade'"
      :error-messages="errors.collect('gasto com mobilidade')"
    />

    <FormFooter right>
      <v-btn
        type="submit"
        color="warning--gradient"
        :loading="isLoading"
        :disabled="isLoading"
        depressed
        rounded
        large
      >
        Definir forma de pagamento
      </v-btn>
    </FormFooter>
  </v-form>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { mask } from 'vue-the-mask'
import { STATUS_IS_LOADING, STATUS_SUCCESS, STATUS_FAILED } from 'utils/constants'
import { isObject, isArray } from 'utils/functions'
import formValidatorMixin from 'mixins/formValidator'
import mediaQueries from 'mixins/mediaQueries'
import persistProspect from 'mixins/persistProspect'
import FormFooter from 'components/FormFooter'

export default {
  name: 'Form',
  mixins: [formValidatorMixin, mediaQueries, persistProspect],
  directives: { mask },
  components: {
    FormFooter
  },
  computed: {
    ...mapState({
      prospect: ({ prospect }) => prospect.data
    }),

    isLoading () {
      return this.currentStatus === STATUS_IS_LOADING
    },

    isSuccess () {
      return this.currentStatus === STATUS_SUCCESS
    },

    isFailed () {
      return this.currentStatus === STATUS_FAILED
    }
  },
  data () {
    return {
      currentStatus: null,
      statesList: [
        { text: 'Acre', value: 'AC' },
        { text: 'Alagoas', value: 'AL' },
        { text: 'Amapá', value: 'AP' },
        { text: 'Amazonas', value: 'AM' },
        { text: 'Bahia', value: 'BA' },
        { text: 'Ceará', value: 'CE' },
        { text: 'Distrito Federal', value: 'DF' },
        { text: 'Espírito Santo', value: 'ES' },
        { text: 'Goiás', value: 'GO' },
        { text: 'Maranhão', value: 'MA' },
        { text: 'Mato Grosso', value: 'MT' },
        { text: 'Mato Grosso do Sul', value: 'MS' },
        { text: 'Minas Gerais', value: 'MG' },
        { text: 'Pará', value: 'PA' },
        { text: 'Paraíba', value: 'PB' },
        { text: 'Paraná', value: 'PR' },
        { text: 'Pernambuco', value: 'PE' },
        { text: 'Piauí', value: 'PI' },
        { text: 'Rio de Janeiro', value: 'RJ' },
        { text: 'Rio Grande do Norte', value: 'RN' },
        { text: 'Rio Grande do Sul', value: 'RS' },
        { text: 'Rondônia', value: 'RO' },
        { text: 'Roraima', value: 'RR' },
        { text: 'Santa Catarina', value: 'SC' },
        { text: 'São Paulo', value: 'SP' },
        { text: 'Sergipe', value: 'SE' },
        { text: 'Tocantins', value: 'TO' }
      ],
      companySizeOptions: [
        { text: '1 a 10', value: '1 a 10' },
        { text: '11 a 20', value: '11 a 20' },
        { text: '21 a 50', value: '21 a 50' },
        { text: '51 a 100', value: '51 a 100' },
        { text: '101 a 500', value: '101 a 500' },
        { text: '501 a 1000', value: '501 a 1000' },
        { text: 'Acima de 1000', value: 'Acima de 1000' }
      ],
      mobilitySpendingOptions: [
        { text: 'Até R$ 1.000,00', value: 'Até R$ 1.000,00' },
        { text: 'De R$ 1.001,00 a R$ 5.000,00', value: 'De R$ 1.001,00 a R$ 5.000,00' },
        { text: 'De R$ 5.001,00 a R$ 10.000,00', value: 'De R$ 5.001,00 a R$ 10.000,00' },
        { text: 'De R$ 10.001,00 a R$ 50.000,00', value: 'De R$ 10.001,00 a R$ 50.000,00' },
        { text: 'Acima de R$ 50.000,00', value: 'Acima de R$ 50.000,00' },
        { text: 'Não sei', value: 'Não sei' }
      ],
      valid: true,
      isAddressLoading: false,
      error: {
        visibility: true,
        message: ''
      },
      form: {
        cnpj: '',
        cep: '',
        state: '',
        city: '',
        address: '',
        addressNumber: '',
        complement: '',
        companySize: '',
        mobilitySpending: ''
      }
    }
  },
  methods: {
    ...mapActions({
      showAlert: 'snackbar/show'
    }),

    isValidObject (val) {
      return isObject(val)
    },

    isValidArray (val) {
      return isArray(val)
    },

    redirectToNextStep () {
      this.$router.push({ name: 'PaymentMethod' })
    },

    toggleAddressLoading (boolean) {
      this.isAddressLoading = boolean
    },

    async isValidCEP (cep) {
      const isValid = await this.$validator.validate('CEP', cep)
      return isValid && Boolean(cep && cep.length === 8)
    },

    isInvalidCEPRequestResponse (res) {
      return !res || res.erro
    },

    async handleGetCEP (val) {
      const cep = val.replace(/\D/g, '')

      if (await this.isValidCEP(cep)) {
        this.toggleAddressLoading(true)

        try {
          const { data: res } = await this.$apiService.getAddress(cep)

          if (this.isInvalidCEPRequestResponse(res)) {
            throw new Error()
          }

          this.resetAddressData()
          this.setAddressData(res.content)
          this.toggleAddressLoading(false)
        } catch (error) {
          this.showAlert({
            type: 'info',
            message: 'Não foi possível localizar o endereço, por favor preencha os campos manualmente.',
            timeout: 8000
          })
          this.toggleAddressLoading(false)

          return error
        }
      }
    },

    resetAddressData () {
      this.form = {
        ...this.form,
        state: '',
        city: '',
        district: '',
        address: ''
      }
    },

    setAddressData (res) {
      const { cep, uf: state, localidade: city, bairro: district, logradouro: address } = res

      this.form = {
        ...this.form,
        cep,
        state,
        city,
        district,
        address
      }
    },

    removeNonNumericChars (str) {
      return str.replace(/\D/g, '')
    },

    renderPayload () {
      const { companyName: name } = this.prospect
      const {
        companySize: numberOfEmployees,
        mobilitySpending: expenditureOnMobility,
        cnpj,
        address: street,
        cep: postalCode,
        complement,
        addressNumber: number,
        district,
        city,
        state
      } = this.form

      const numberOfEmployeesObj = numberOfEmployees.trim()
        ? { numberOfEmployees }
        : {}
      const expenditureOnMobilityObj = expenditureOnMobility.trim()
        ? { expenditureOnMobility }
        : {}
      const complementObj = complement.trim()
        ? { complement }
        : {}

      return {
        name,
        ...numberOfEmployeesObj,
        ...expenditureOnMobilityObj,
        cnpj: this.removeNonNumericChars(cnpj),
        address: {
          street,
          postalCode: this.removeNonNumericChars(postalCode),
          // complement,
          ...complementObj,
          number,
          district,
          city,
          state
        }
      }
    },

    isInvalidRequestResponse (res) {
      return !this.isValidObject(res) || (this.isValidObject(res) && !res.valid)
    },

    hasCredit (res) {
      return res && res.content && res.content.hasCredit
        ? res.content.hasCredit
        : false
    },

    captureErrorMessage (payload) {
      return payload && this.isValidArray(payload.reasons)
        ? payload.reasons[0]
        : null
    },

    companyAlreadyRegistered (error) {
      return /já\s+cadastrada\./.test(error)
    },

    updateErrorMessage (error) {
      this.error = {
        ...this.error,
        ...error
      }
    },

    async handleSubmit () {
      const isValid = await this.$validator.validateAll()
      const errorPayload = {
        valid: false,
        reasons: ['Ocorreu um erro ao enviar as informações, tente novamente mais tarde.'],
        message: 'Ocorreu um erro ao enviar as informações, tente novamente mais tarde.'
      }

      try {
        if (isValid) {
          this.currentStatus = STATUS_IS_LOADING

          const { id: companyId } = this.prospect
          const payload = this.renderPayload()

          // CREATE COMPANY (STEP 2)
          const { data: createCompany } = await this.$apiService.step2.createCompany(companyId, payload)
          if (this.isInvalidRequestResponse(createCompany)) {
            this.updateErrorMessage({
              visibility: true,
              message: this.captureErrorMessage(createCompany)
            })
            throw new Error()
          }

          // UPDATE DEAL CONTACT BY CORPORATE ID (STEP 3)
          const { data: updateDealContactByCoporateId } = await this.$apiService.step3.updateDealContactByCorporateId(companyId)
          if (this.isInvalidRequestResponse(updateDealContactByCoporateId)) {
            this.updateErrorMessage({
              visibility: true,
              message: this.captureErrorMessage(updateDealContactByCoporateId)
            })
            throw new Error()
          }

          // CROSS CORE VALIDATION (STEP 4)
          const { jsc: colectorJsc, hdim: colectorHdim } = this.$sc.submit()
          const { data: crossCoreValidation } = await this.$apiService.step4.crossCoreValidation(companyId, {
            colectorJsc,
            colectorHdim
          })
          if (this.isInvalidRequestResponse(crossCoreValidation)) {
            this.$router.push({ name: 'RegistrationDenied' })
            this.updateErrorMessage({
              visibility: false,
              message: this.captureErrorMessage(crossCoreValidation)
            })
            throw new Error()
          }

          // CREDIT VALIDATION (STEP 5)
          const { data: creditValidation } = await this.$apiService.step5.creditValidation(companyId)
          if (this.isInvalidRequestResponse(creditValidation)) {
            this.updateErrorMessage({
              visibility: true,
              message: this.captureErrorMessage(creditValidation)
            })
            throw new Error()
          }

          /**
           * hasCredit === false (only credit card)
           * hasCredit === true (credit card and billet)
           */
          const hasCredit = this.hasCredit(creditValidation)

          this.saveProspect({
            currentStep: 3,
            data: {
              hasCredit
            }
          })

          this.currentStatus = STATUS_SUCCESS
          this.redirectToNextStep()
        }
      } catch (error) {
        this.currentStatus = STATUS_FAILED

        if (this.error.visibility) {
          const message = this.error.message || errorPayload.message
          const companyAlreadyRegistered = this.companyAlreadyRegistered(this.error.message)

          this.showAlert({
            type: companyAlreadyRegistered ? 'info' : 'error',
            message: companyAlreadyRegistered
              ? 'CNPJ já cadastrado. Entre em contato com o gestor da sua conta.'
              : message,
            timeout: 8000
          })
        }

        return error
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.cep__info {
  font-size: 12px;
  line-height: normal;
  padding-top: 31px;

  a {
    font-size: 12px;
  }
}
</style>
